import { handleAxiosError } from "errors/handleAxiosError";
import { axiosJson } from "./config";

export const verifyUser = async(url, token) => {
	if (!url || !token) throw new Error('You must pass url and token!');
	try {
		const response = await axiosJson.get(url, {headers: {Authorization: `Bearer ${token}`}});
		if (response && response.data) return {data: response.data, error: null};
		else return {data: null, error: 'Something went wrong! Please try again later.'};
	} catch(err) {
		return {data: null, error: handleAxiosError(err)};
	}
};