import axios from 'axios-service/config';
import useGlobalState from './useGlobalState';

const useLogout = () => {
	const { setUser, setAccessToken, setIsVerifyingUser } =
    useGlobalState();

	/**
	 * 
	 * @param {Boolean} fetch - Send request to backend to clear refreshtoken too - default true 
	 */
	const logout = async (fetch = true, setVerifying = false) => {
		if (fetch) await axios('/users/auth/logout', { withCredentials: true });

		setAccessToken(null);
		if (typeof window !== 'undefined') {
			localStorage?.removeItem('accessToken');
		}

		setUser(null);

		if (setVerifying) setIsVerifyingUser(false);
	};

	return logout;
};

export default useLogout;
