import axios from 'axios';
import { handleAxiosError } from 'errors/handleAxiosError';
import { API_ENDPOINT } from 'globalConstants';

const baseURL = API_ENDPOINT;

export default axios.create({
	baseURL
});

export const axiosJson = axios.create({
	baseURL,
	headers: { 'Content-Type': 'application/json'},
	// withCredentials: true
});

export const axiosJsonEncoded = axios.create({
	baseURL,
	headers: { 'Content-Type': 'application/json',  'Accept-Encoding': 'application/json'},
	withCredentials: true
});

export const axiosAuth = axios.create({
	baseURL,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

export const axiosFile = axios.create({
	baseURL,
	headers: { 'Content-Type': 'multipart/form-data' },
	withCredentials: true
});

export const generalAxios = async(method, url, data={}) => {

	if ( !method || !url ) throw new Error('You must pass method and url!');
	try {
		const response = await axiosJsonEncoded[method?.toLowerCase()](url, data);

		if (response && response.data) return {data: response.data, error: null};
		else return {data: null, error: 'Something went wrong! Please try again later.'};
	} catch(err) {
		return {data: null, error: handleAxiosError(err)};
	}
};