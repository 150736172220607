import useGlobalState from './useGlobalState';
import {useNavigate} from 'react-router-dom';
// import useConnectSocket from './utils/useConnectSocket';

const usePopulateDataFromBackend = () => {
	const navigate = useNavigate();
	// const connectSocket = useConnectSocket();
	const { setUser, setAccessToken, setIsVerifyingUser } = useGlobalState();
	
	/**
     * 
     * @param {Object} data - Object with accessToken, user, and other data
	 * @param {Boolean} [shouldConnectSocket] whether new socket conn should be init
     * @param {Boolean} [shouldNavigate] - boolean - should you navigate away
     * from the current page - default - true
     * @param {Boolean} [replace] - boolean - should you replace current page
     * in stack or not - default is false - so it will push to history stack
     * not replace, unless else specified
     * @param {String} [path] - string containing relative path - where to navigate
     */
	const populate = (data, shouldNavigate = true, replace = false, path = '/') => {
		if (!data || typeof data !== 'object') throw new Error('First argument to populate function (from usePopulateDataFromBackend) must be an object containing data from backend to set in context!');

		console.log('populate data: ', data);

		if (data.accessToken) {
			setAccessToken(data.accessToken);
			if (typeof window !== 'undefined') localStorage?.setItem('accessToken', data.accessToken);
		}

		if (data.user) setUser(data.user);

		// if (shouldConnectSocket) {
		// 	if (socket) {
		// 		socket.disconnect();
		// 		setSocket(null);
		// 	}
		// 	connectSocket(data.accessToken || null);
		// }

		setIsVerifyingUser(false);

		if (shouldNavigate) {
			if (replace) navigate(path, {replace: true});
			else navigate(path);
		}
	};

	return populate;
};

export default usePopulateDataFromBackend;