const defaultServerErrorTitle = 'Server Error';
const defaultServerErrorMessage = 'Something went wrong! Please try again later.';

const defaultClientErrorTitle = 'Connection Error';
const defaultClientErrorMessage = 'An error has occurred and your request could not reach our servers! Please check your connection and try again.';

const defaultConfigErrorTitle = 'Config Error';
let defaultConfigErrorMessage = 'An error has occurred in setting up the request and it could not reach our servers! Please contact support!';

// if (process?.env?.NODE_ENV === 'development') {
// 	defaultConfigErrorMessage = 'An error has occurred during configuration of request! Please check your code and make sure that all your imports and axios configurations are in order - method, url, data!';
// }

export const handleAxiosError = (err) => {
	if (!err) throw new Error('You must pass error object that you got back from axios to handleAxiosError helper, for this function to parse it and return the custom error object to you!');

	console.log('AXIOS ERROR: ', err);

	let title;
	let message;
	let data;
	let status;

	if (err.response) {
		// Request has reached server and server responded with a status code
		// that falls out of the range of 200 - 299
		if (err.response.data) {
			if (typeof err.response.data === 'object') {
				title = err.response.data.title; 
				// || defaultServerErrorTitle;
				message = err.response.data.message || err.response.data.error || defaultServerErrorMessage;
				data = err.response.data.data || null;
			} else {
				// title = defaultServerErrorTitle;
				message = defaultServerErrorMessage;
			}
		} else {
			title = defaultServerErrorTitle;
			message = defaultServerErrorMessage;
		}
		if (err.response.status) status = err.response.status;
	} else if (err.request) {
		// Request was made but never reached server and no response
		// Can be due to clients internet connection ...
		title = defaultClientErrorTitle;
		message = defaultClientErrorMessage;
		if (err.request.status) status = err.request.status;
	} else {
		// Something happened in setting up the request that triggered an Error
		// Usually developer error (imports, instance creation, ...)
		title = defaultConfigErrorTitle;
		message = defaultConfigErrorMessage;
	}

	let obj = {
		message,
	};

	if (title) obj.title = title;
	if (data) obj.data = data;
	if (status) obj.status = status;

	return obj;
};