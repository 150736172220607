import React from 'react';
import ReactDOM from 'react-dom/client';

import ContextProvider from 'context/ContextProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import App from './App';

import 'styles/general/globals.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <ContextProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </ContextProvider>
    </BrowserRouter>
);

