const { useState, useCallback } = require("react");

const useRefresh = () => {
    const [update, setUpdate] = useState(0);
    const refresh = useCallback(() => {
        setUpdate(prev => prev+1);
    }, []);

    return [update, refresh]
}

export default useRefresh;