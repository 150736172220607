import useGlobalState from '../useGlobalState';
import { verifyUser } from 'axios-service/auth';
import usePopulateDataFromBackend from 'hooks/usePopulateDataFromBackend';
import useLogout from 'hooks/useLogout';

const useVerifyUser = () => {
	const { accessToken } = useGlobalState();

	const populate = usePopulateDataFromBackend();
	const logout = useLogout();

	const verify = async () => {
		const {data, error} = await verifyUser('/users/auth/verify', accessToken);
		if (data) populate(data, false);
		if (error) logout(false, true);
	};

	return verify;
};

export default useVerifyUser;
