import { isObject } from 'hooks/forms/useForm';
import {createContext, useCallback, useState} from 'react';

export const AdminContext = createContext({});

const ContextProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(typeof window !== 'undefined' ? localStorage?.accessToken ? localStorage?.getItem('accessToken') : null : null);
    // const [socket, setSocket] = useState(null);
    const [isVerifyingUser, setIsVerifyingUser] = useState(true);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [confirm, setConfirm] = useState(null);

    const confirmModal = useCallback((obj) => {
        if (!obj || !isObject(obj) || !obj.onYes || !obj.data) {
            console.error('To confirmModal function you must pass an object that must have onYes function callback property, and data prop (with all the item data that you want to pass to your onYes function). Optionaly, you can also pass message to display, yesButtonText, noButtonText and onNo callback!');
            return;
        }
        setConfirm(obj);
    }, [])

    let value = {
        user,
        setUser,
        accessToken,
        setAccessToken,
        isVerifyingUser,
        setIsVerifyingUser,

        error,
        setError,
        success,
        setSuccess,
        confirm,
        confirmModal,
        setConfirm
    }

    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    )
}

export default ContextProvider;