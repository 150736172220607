import { Routes, Route } from 'react-router-dom';
import { Suspense, lazy, useEffect } from "react";
import useGlobalState from 'hooks/useGlobalState';
import useVerifyUser from 'hooks/utils/useVerifyUser';
import LoadingPage from 'components/UI/LoadingPage';

// Lazy load pages!
const Main = lazy(() => import('layout/Main'));
const Dashboard = lazy(() => import('layout/Dashboard'));

const Login = lazy(() => import('pages/Login'));
const Page404 = lazy(() => import('pages/Page404'));
const Forbidden = lazy(() => import('pages/Forbidden'));
const Logout = lazy(() => import('pages/Logout'));

const PersistLogin = lazy(() => import('layout/PersistLogin'));
const RequireAuth = lazy(() => import('layout/RequireAuth'));

const Home = lazy(() => import('pages/Home'));

const Users = lazy(() => import('pages/Users'));
const UsersForm = lazy(() => import('pages/UsersForm'));

const Attributes = lazy(() => import('pages/Attributes'));
const AttributesForm = lazy(() => import('pages/AttributesForm'));

const Categories = lazy(() => import('pages/Categories'));
const CategoriesForm = lazy(() => import('pages/CategoriesForm'));

const Products = lazy(() => import('pages/Products'));
const ProductsForm = lazy(() => import('pages/ProductsForm'));

const Stores = lazy(() => import('pages/Stores'));
const StoresForm = lazy(() => import('pages/StoresForm'));

const Blog = lazy(() => import('pages/Blog'));
const BlogForm = lazy(() => import('pages/BlogsForm'));

const BlogCategories = lazy(() => import('pages/BlogCategories'));
const BlogCategoriesForm = lazy(() => import('pages/BlogCategoriesForm'));

function App() {
  const { user, accessToken, isVerifyingUser, setIsVerifyingUser } = useGlobalState();

  const verify = useVerifyUser();

  useEffect(() => {
    let isMounted = true;
    const verifyUser = async () => {
      try {
        await verify();
      } catch (err) {
        console.error(err);
      } finally {
        setIsVerifyingUser(false);
      }

    }

    accessToken && !user && isMounted ? verifyUser() : setIsVerifyingUser(false);

    return () => {
      isMounted = false
    }
  }, []);

  if (isVerifyingUser) return <LoadingPage />

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route element={<Main />}>

          {/* Public routes */}
          <Route path='/login' element={<Login />} />
          <Route path='/forbidden' element={<Forbidden />} />
          <Route path='/logout' element={<Logout />} />

          {/* Protected Routes */}

          {/* Stay logged in if refresh token is valid and 'Remember me' option was checked */}
          <Route element={<PersistLogin />}>

            <Route element={<RequireAuth allowedUserLevels={['admin', 'support']} />}>
              <Route element={<Dashboard />}>
                {/* Nest all routes that can be accessed by both admin and support here: */}
                <Route path='/' element={<Home />} />
                {/* ... */}
              </Route>
            </Route>

            <Route element={<RequireAuth allowedUserLevels={['admin']} />}>
              <Route element={<Dashboard />}>
                {/* Nest all routes that can be accessed by just admin: */}

                <Route path='/users' element={<Users />} />
                <Route path='/users/:userId' element={<UsersForm />} />

                <Route path='/attributes' element={<Attributes />} />
                <Route path='/attributes/:attributeId' element={<AttributesForm />} />

                <Route path='/categories' element={<Categories />} />
                <Route path='/categories/:categoryId' element={<CategoriesForm />} />

                <Route path='/products' element={<Products />} />
                <Route path='/products/:productId' element={<ProductsForm />} />

                <Route path='/stores' element={<Stores />} />
                <Route path='/stores/:storeId' element={<StoresForm />} />

                <Route path='/blog' element={<Blog />} />
                <Route path='/blog/:blogId' element={<BlogForm />} />

                <Route path='/blog-categories' element={<BlogCategories />} />
                <Route path='/blog-categories/:blogCategoryId' element={<BlogCategoriesForm />} />

              </Route>
            </Route>

            <Route element={<RequireAuth allowedUserLevels={['support']} />}>
              <Route element={<Dashboard />}>
                {/* Nest all routes that can be accessed by just support: */}
                {/* ... */}
              </Route>
            </Route>

          </Route>

          {/* Catch all - 404 page */}
          <Route path='*' element={<Page404 />} />

        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
