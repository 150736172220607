import useRefresh from "hooks/useRefresh";
import { useCallback, useMemo, useState } from "react";

/**
 * @typedef {Function} FormInputValidatorFunction
 * @param {*} value - Gets value
 * @returns {boolean} - True if is valid and false otherwise
 */

/**
 * @typedef {Object} FormInputPropObject
 * @property {string} [type] - Type of form input - default 'text'
 * @property {*} [initialValue] - Initial value of input - usually '' - default
 * is set based on the type
 * @property {FormInputValidatorFunction} [validate] - Validator function
 * @property {string} [errorMessage] - error message to display - default depends on type - usually 'Invalid input!'
 * @property {string} [placeholder] - Placeholder for input field
 * @property {string} [label] - Label for input field
 * @property {number} [span] - Grid span for field - 1-12 - default 12
 * @property {Array} [values] - array of values for select or radio input
 * @property {string} [className] - Classname for input
 * @property {boolean} [disabled] - Whether the input should be disabled
 * @property {string} [name] = name for input - optional - name will be set to prop name
 * @property {boolean} [singleImage] - if single image should be in dropzone!
 * 
 */

/**
 * @typedef {Object} FormInputReturnObject
 * @property {boolean} isValid - Is input valid
 * @property {boolean} hasError - Does input have an error (after blur)
 * @property {string} errorMessage - Error message to display if error
 * @property {string} type - Type of input
 * @property {string} placeholder - Placeholder for input field
 * @property {string} label - Label for input field
 * @property {number} span - Grid span for field - 1-12 - default 12
 * @property {Array} values - array of values for select or radio input
 * @property {boolean} singleImage - is single image
 * @property {Function} handleChange - function for onChange event
 * @property {Function} handleBlur - function for onBlur event
 * @property {Function} triggerInputValidation - function to trigger validation
 * @property {Function} onPopulateData - function to set value to one we got
 * from backend
 * @property {Function} get - get value
 * @property {Function} set - set value
 * @property {Function} reset - reset value
 */

/**
 * 
 * @param {FormInputPropObject} param0 
 * @returns {FormInputReturnObject} - Object with many props
 */
const useFormInput = ({
	type = 'text',
	initialValue,
	validate: initValidate,
	errorMessage: initError,
	placeholder = '',
	label,
	span = 12,
	values: initValues = null,
	className = '',
	style = {},
	disabled = false,
	name = null,
	required = false,
	singleImage = false,
}) => {

	let val = initialValue
		? initialValue
		: type ?
			(type === 'images' && !singleImage) || type === 'array'
				? []
				: type === 'checkbox' || type === 'switch'
					? false
					: (type === 'images' && singleImage)
						? null :
						''
			: '';

	let validate = initValidate
		? initValidate
		: type
			? type === 'select'
				? value => value
					? true
					: false
				: (type === 'images' && !singleImage)
					? value => (value && Array.isArray(value) && value.length && value.some(obj => obj?.main === true))
					: () => true
			: () => true;

	const values = useMemo(() => {
		if (type && ['radio', 'select', 'select-search', 'array'].includes(type)) {
			if (!initValues || !Array.isArray(initValues)) throw new Error('When useForm data field is of type radio or select - it must have a values field - this field is an array of values to display as radio buttons/select options - it can be an object : {fieldName: "String To Display"} or a camelCase string - which will be translate to regular text for label on the fly!');
			return initValues.map(element => {
				if (typeof element === 'string') {
					if (element === '') return { value: '', label: 'Please select one of the options' }
					else return { value: element, label: element.replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase()) };
				}
				else if (typeof element === 'object') {
					const prop = Object.keys(element)[0];
					return { value: prop, label: element[prop] };
				}
				else throw new Error('When useForm data field is of type radio or select - it must have a values field - this field is an array of values to display as radio buttons/select options - it can be an object : {fieldName: "String To Display"} or a camelCase string - which will be translate to regular text for label on the fly!');
			});
		} else return null;
	}, [initValues, type]);

	const errorMessage = useMemo(() => {
		if (initError) return initError;
		if (type && ['radio', 'select', 'select-search'].includes(type)) return 'You must select one of the provided values!';
		if (type && type === 'images' && !singleImage) return 'Please select item pictures and select one of the as main picture!';
		if (type && type === 'images' && singleImage) return 'Please select an image!';
		if (type && type === 'html') return 'Please enter some content!';
		else return 'Invalid input!';
	}, [initError, type]);

	const [value, set] = useState(val);
	const [isTouched, setIsTouched] = useState(false);
	const [populatedData, setPopulatedData] = useState(null);
	const [backendData, triggerBackendData] = useRefresh();

	const isValid = required
		? ((type && type === 'select-search' ? value?.value : value) && validate(type && type === 'select-search' ? value?.value : value))
		: validate(type && type === 'select-search' ? value?.value : value);
	const hasError = !isValid && isTouched;
	const isValidAndTouched = isValid && isTouched;

	const handleChange = useCallback((event) => {
		if (type && ['checkbox', 'switch'].includes(type)) return set(prev => !prev);
		if (type === 'images' && singleImage) {
			set(event.image);
			return;
		}
		else if (type && ['images', 'array'].includes(type)) {
			if (!event?.arr) throw new Error('If type is images or array - you must pass object with arr property that holds an array - to handleChange!');
			set(event.arr);
		}
		else if (type && type === 'select-search') set(event);
		else if (type && type === 'html') set(event);
		else set(event?.target?.value);
	}, [type]);

	const handleBlur = useCallback(() => setIsTouched(true), []);

	const get = () => {
		if (type && type === 'select-search') return value?.value;
		else return value;
	};

	const reset = useCallback(() => {
		setIsTouched(false);
		set(val);
	}, []);

	const onPopulateData = useCallback((newValue, replaceInitialValue = false) => {
		set(newValue);
		triggerBackendData();

		if (type && type === 'images') setPopulatedData(newValue);

		if (replaceInitialValue) val = newValue;
	}, [type]);

	return {
		value,
		isValid,
		hasError,
		isValidAndTouched,
		errorMessage,
		type,
		placeholder,
		label,
		span,
		values,
		className,
		style,
		disabled,
		name,
		backendData,
		singleImage,

		populatedData,

		handleChange,
		handleBlur,
		triggerInputValidation: handleBlur,
		onPopulateData,

		get,
		set,
		reset
	};
};

export default useFormInput;