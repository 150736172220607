import React from 'react';
import styles from 'styles/components/UI/LoadingSpinner.module.scss';

const LoadingPage = () => {
	return (
        <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div className={styles.load}>
                <hr />
                <hr />
                <hr />
                <hr />
            </div>
        </div>
	);
};

export default LoadingPage;