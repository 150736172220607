import { useCallback, useMemo } from "react";
import useFormInput from "./useFormInput";

/**
 * @typedef {Object} FormField
 * @property {string} [initialValue] - The initial value of the field - if not
 * set - default will be provided based on type - for select and radio should
 * not be specified!
 * @property {string} [type] - The type of the field to render - default 'text'
 * @property {function} [validate] - The validation function for the field - by
 * default it will return true - if no function is specified
 * @property {string} [errorMessage] - Error message to display if input is bad
 * - if ommited default is set based on type
 * @property {string} [placeholder] - Placeholder for the input field
 * @property {string} [label] - Label for the field - it is then automatically
 * binded with id for input field
 * @property {number} [span] - How much of the grid will it take relative to 
 * parent - default is 12 - full length
 * @property {Array} [values] - array of values to pass with select
 * @property {string} [className] - Classname for input
 * @property {Object} [style] - Inline style to pass to input
 * @property {boolean} [disabled] - Whether the input should be disabled
 * @property {string} [name] - name for input - optional - name will be set to prop name
 * @property {boolean} [required] - is value required - default false
 * @property {boolean} [singleImage] - optional for dropzone - if just one image should be submitted!
 */

/**
 * @typedef {Record<string, FormField>} Data
 * @property {FormField} * - A property with any name, and value of an FormField object {@link FormField}
 */

/**
 * @typedef {Object} Item
 * @property {string} [title] - The title to display above the input fields - optional
 * @property {string} [className] - Class name to give to container for all fields in 
 * this objects data field including title if specified. Title is then nested inside 
 * an h6 html tag, and data inside separate container div. Optional
 * @property {string} [parentObj] - If fields is a part of an object (nested inside it) - 
 * parentObj should be specified and should be the name of the parent object: for example -
 * if we have shippingAddress field, with fields: city, zip, etc, parentObj should then be
 * shippingAddress, and the data inside would be: city: {...}, zip: {...}
 * @property {number} [span] - How large should the container be in grid (1-12)
 * @property {Data} data - The data objects specifying just how to create the form. Use
 * autosuggestion to properly set it up
 */

/**
 * @typedef {Object} FormObject
 * @property {Array} form - form data array to pass to Form component
 * @property {boolean} isDisabled - If all fields are valid - false - else true - should be 
 * used on the submit button
 * @property {Function} validateForm - Function to run validation on each of the fields - will
 * return null if no error - otherwise will return an array of objects where each props is field
 * name and value is error message
 * @property {Function} resetForm - Function to reset form - return all fields to initialValue and
 * remove any error/success UI elements
 * @property {Function} submitForm - Form to generate submit obj from form data arr
 */

/**
 * @typedef {Function} PopulateFormDataFunction
 * @param {Object} obj - Object whose field names should be the same as in the form data (of all
 * objects passed in an array to useForm)
 */

/**
 * @typedef {Object} ConfigObject
 * @property {boolean} [checkIsDisabled] - Whether the hook should track isDisabled state for submit button (default true) - put it to false to always have submit button enabled - note - this will not remove validation!
 * @property {boolean} [replaceInitialValueOnPopulate] - Whether the hook should, after populateFormValues function is called - replace the initial value with the one it got from backend - default is false - handles reset click by user - when user clicks on reset form will they go back to empty form (with initial values - usually '') or go back to data from backend
 * @property {Object} [initialDataObj] - object with data from which to create a form array - if form array is dynamic (we don't know all the fields upfront)! Should not be used, as there is not styling then, or anything else...
 */

/**
 * @author Radan Jovic <radanjovic1994@gmail.com>
 * @description - A custom hook for handling forms with ease
 * @param {Item[]} ARR - An array of item objects - from which to create a form
 * @param {ConfigObject} [OBJ] - An object of config data for form
 * @example <caption>Example usage of useFormHook</caption>
 * // TODO
 * @returns {[FormObject, PopulateFormDataFunction]} - An array containing an object and a function
 */
const useForm = (ARR, OBJ = {
	checkIsDisabled: true, 
	replaceInitialValueOnPopulate: false, 
	initialDataObj: null
}) => {
	// console.time('useForm');
	if (!ARR || !Array.isArray(ARR) || (ARR?.length === 0 && (!OBJ?.initialDataObj || !isObject(OBJ?.initialDataObj)))) throw new Error('useForm hook takes an array of objects as argument! Each of the objects provided must have data property which itself is an object of all the fields that the hook needs to generate. Check the documentation for this hook and/or provided example to see how to use it.');
	// eslint-disable-next-line react-hooks/rules-of-hooks
	if (ARR?.length === 0 && OBJ?.initialDataObj) ARR = useMemo(() => generateFormArray(OBJ?.initialDataObj), [OBJ?.initialDataObj]); 

	
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const form = ARR.map(obj => ({...obj, data: Object.keys(obj.data)?.reduce((dataObj, item) => ({...dataObj, [item]: useFormInput(generateInputs(obj.data[item]))}), {})}));

	// Should form be disabled
	const isDisabled = !OBJ?.checkIsDisabled
		? false
		: form.reduce((res, obj) => {
			const every = Object.values(obj.data)?.reduce((res2, obj2) => res2 || obj2.isValid === false, false);
			return res || every;
		}, false);

	/**
	 * @description - Function to validate form inputs by users in accordance with validate functions for each field
	 * @param {Array} arr - Data array produces by useForm hook
	 * @return {Object | null} - null if no errors, object with erros if errors
	 */
	const validateForm = useCallback((arr) => {
		if (!arr || !Array.isArray(arr)) throw new Error('validateForm function must receive form data array as an argument!');
		let errors = {};
		for (let i = 0; i < arr.length; i++) {
			Object.keys(arr[i].data)?.forEach(prop => {
				if (!arr[i].data[prop]?.isValid) {
					if (arr[i].parentObj) {
						errors[arr[i].parentObj] = {
							...(errors[arr[i].parentObj] || {}),
							[prop]: arr[i].data[prop]?.errorMessage
						};
					} else {
						errors[prop] = arr[i].data[prop]?.errorMessage;
					}
				}
			});
		};
		if (errors && Object.keys(errors)?.length === 0) return null;
		triggerFormValidation(arr);
		return errors;
	}, []);

	/**
	 * @description - Function to trigger blur on every separate field
	 * @param {Array} arr - Data array produces by useForm hook
	 * @return {void} - no return just executes methods on input
	 */
	const triggerFormValidation = useCallback((arr) => {
		if (!arr || !Array.isArray(arr)) throw new Error('triggerFormValidation function must receive form data array as an argument!');
		for (let i = 0; i < arr.length; i++) {
			Object.keys(arr[i].data)?.forEach(prop => {
				arr[i]?.data?.[prop]?.triggerInputValidation();
			});
		}
	}, []);

	/**
	 * @description - Function to trigger blur on every separate field
	 * @param {Array} arr - Data array produces by useForm hook
	 * @return {void} - no return just executes methods on inputs
	 */
	const resetForm = useCallback((arr) => {
		if (!arr || !Array.isArray(arr)) throw new Error('resetForm function must receive form data array as an argument!');
		for (let i = 0; i < arr.length; i++) {
			Object.keys(arr[i].data)?.forEach(prop => {
				arr[i]?.data?.[prop]?.reset();
			});
		}
	}, []);

	const populateFormValues = (obj) => {populate(obj, form);};

	/**
	 * @description - Function to populate form with data from backend
	 * @param {Object} obj - Object with data to populate
	 * @returns {void} - no return just executes methods on inputs
	 */
	const populate = useCallback((obj, arr) => {
		if (!isObject(obj) || Object.keys(obj).length <= 0) throw new Error('First argument supplied to populate must be an object whose properties match the ones in the form data!');
		if (!arr || !Array.isArray(arr)) throw new Error('Second argument passed to populate function must be form data array!');
		arr.forEach(item => {
			if (item.parentObj) {
				Object.keys(item.data)?.forEach(prop => {
					if (exists(obj[item.parentObj]) && exists(obj[item.parentObj][prop])) {
						let value = obj[item.parentObj][prop];
						if (item.data[prop].type === 'select-search') value = {
							value: obj[item.parentObj][prop],
							label: obj[item.parentObj][prop],
						}
						item.data[prop]?.onPopulateData(value, OBJ?.replaceInitialValueOnPopulate);
					}
				  });
			} else {
				Object.keys(item.data).forEach(prop => {
					if (exists(obj[prop])) {
						let value = obj[prop];
						if (item.data[prop].type === 'select-search') value = {
							value: obj[prop],
							label: obj[prop],
						}
						item.data[prop]?.onPopulateData(value, OBJ?.replaceInitialValueOnPopulate);
					}
				  });
			}
		});
	}, []);

	const submitForm = useCallback((arr) => {
		if (!arr || !Array.isArray(arr)) throw new Error('submitForm function must receive form data array as an argument!');
		let data = {};
		arr.forEach(obj => {
			if (obj.parentObj) {
				data[obj.parentObj] = {};
				Object.keys(obj.data)?.forEach(prop => {
					data[obj.parentObj][prop] = obj.data[prop].get();
				});
			} else {
				Object.keys(obj.data)?.forEach(prop => {
					data[prop] = obj.data[prop].get();
				});
			}
		});
		return data;
	}, []);

	// console.timeEnd('useForm');
	return [{form, isDisabled, validateForm, resetForm, submitForm}, populateFormValues];
};

export default useForm;

/**
 * 
 * @param {Object} obj - Object with initial fields to populate the data from
 */
const generateFormArray = (obj) => {
	const arr = [];

	Object.keys(obj).forEach((prop, index) => {
		if (typeof obj[prop] === 'object') {
			arr.push({
				title: prop.replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase()),
				parentObj: prop,
				className: `${prop}-${index}`,
				data: Object.keys(obj[prop]).reduce((data, nestedProp) => ({
					...data,
					[nestedProp]: {
						initialValue: obj[prop][nestedProp],
						label: nestedProp.replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase())
					}
				}), {})
			});
		} else {
			arr.push({
				className: `${prop}-${index}`,
				data: {
					[prop]: {
						initialValue: obj[prop],
						label: prop.replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase())
					}
				}
			});
		}
	});

	return arr;
};

export const isObject = (obj) => typeof obj === 'object' && !Array.isArray(obj) && ![null, undefined].includes(obj);

export const exists = (value) => !([undefined, null].includes(value) || typeof value === 'undefined');

const generateInputs = (obj) => {
	if (!isObject(obj)) throw new Error('Value of data each field submitted to custom useForm hook must be an object which can contain any of the input field values to override defaults!');
	return obj;
};